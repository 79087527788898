
// const backgrounds = [
// ]

// const background = backgrounds[Math.floor(Math.random() * backgrounds.length)]
import Svg404 from "~/components/404NotFound.vue";
export default {
  components: {
    Svg404,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    toDefaultPage() {
      if (this.$cookies.get("CountryLocale")) {
        return `/${this.$cookies.get("CountryLocale").replace("/", "-")}`;
      }
      return "/";
    },
  },
  created() {
    console.log("here");
    console.log(this.$store.state.isLoading);
    if (this.$cookies.get("CountryLocale")) {
      this.$store.dispatch(
        "loadTranslationJsonData",
        this.$cookies.get("CountryLocale").replace("/", "-")
      );
    } else {
      this.$store.dispatch("loadTranslationJsonData", "int-en");
    }
  },
  layout: "blank",
};
